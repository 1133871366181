import {createAction, handleActions} from 'redux-actions';

const CHANGE_INPUT = 'colorConvert/CHANGE_INPUT';
const CONVERT = 'colorConvert/CONVERT';
const PICKERCONVERT = 'colorConvert/PICKERCONVERT';

export const changeInput = createAction(CHANGE_INPUT, input => input);
export const convert = createAction(CONVERT, value => value);
export const pickerConvert = createAction(PICKERCONVERT, value => value);

// state
const initailState = {
  input: {
    key: '',
    value: 0,
  },
  value: '',
  pickerValue: '',
}

// reducer
export default handleActions({
    [CHANGE_INPUT]: (state, action) => ({
      ...state,
      input: action.payload,
    }),
    [CONVERT]: (state, action) => ({
      ...state,
      value: action.payload,
    }),
    [PICKERCONVERT]: (state, action) => ({
      ...state,
      pickerValue: action.payload,
    }),
  },
  initailState,
);