import React, { Component } from 'react';

class colorConvertForm extends Component {
  render() {
    const {input, value, onChange, onSubmit} = this.props;
    
    return (
      <form action onSubmit={onSubmit} action="">
        <div className="flex-box">
          {/* RGB => HEX */}
          <div className="flex-item">
            <div className="dashed-wrap">
              <h4 className="content-title">RGB↔HEX</h4>
              <div className="input-wrap">
                <label htmlFor="rgbConvert">RGB 값 입력</label>
                <input id="rgbConvert" type="text" value={input.key == 'rgbConvert' ? input.value : ''} onChange={onChange} />
              </div>
              <div className="info-wrap">
                <h5 className="info-title deco-text">RGB를 HEX로 변환하는 경우</h5>
                <ul className="info-list">
                  <li>1. RGB 입력박스에 RGB값(예: 255, 255, 255)을 입력해주세요.
                    <br />
                    <span className="info-sub">*반드시 콤마(,)로 구분해주세요.</span>
                  </li>
                  <li>2. ‘변환‘ 버튼 클릭</li>
                  <li>3. 하단에 표시되는 색상코드 확인</li>
                </ul>
              </div>
            </div>
          </div>
          {/* HEX => RGB */}
          <div className="flex-item">
            <div className="dashed-wrap">
              <h4 className="content-title">HEX↔RGB</h4>
              <div className="input-wrap">
                <label htmlFor="hexConvert">HEX 값 입력</label>
                <input id="hexConvert" type="text" value={input.key == 'hexConvert' ? input.value : ''} onChange={onChange} />
              </div>
              <div className="info-wrap">
                <h5 className="info-title deco-text">HEX를 RGB로 변환하는 경우</h5>
                <ul className="info-list">
                  <li>1. HEX 입력박스에 색상코드(예: #ffffff)를 입력해주세요.</li>
                  <li>2. ‘변환‘ 버튼 클릭</li>
                  <li>3. 하단에 표시되는 RGB 색상값 확인</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <p>
          <button type="submit" id="convertBtn" className="default-btn">변환</button>
        </p>
        <div className="result-wrap">
          <p>변환된 값 <span className="result">{value}</span></p>
        </div>
      </form>
    );
  }
}

export default colorConvertForm;