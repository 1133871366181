import React, { Component } from 'react';
import {Route, Link} from 'react-router-dom';

import './Common.scss';

class Navigation extends Component {
    render() {
        const {nav, toggleNav} = this.props;
      
        return (
            <div id="navigation" className={nav == true ? 'active' : ''}>
              <div className="nav-layer" />
              <div className="nav-container">
                <div className="nav-header">
                  <h2 className="header-logo white"><a href="/">Toolo</a></h2>
                  <div id="navSvg" className={nav == true ? 'cross' : ''} onClick={toggleNav}>
                    <svg viewBox="0 0 800 600">
                      <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="gnbBtnTop" />
                      <path d="M300,320 L540,320" id="gnbBtnMiddle" />
                      <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="gnbBtnBottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) " />
                    </svg>
                  </div>
                </div>
                
                <div id="nav">

                  <div className="tool-category">
                    <h3 className="category-name">DESIGN</h3>
                    <ul className="tool-list">
                      <li><Link to="/pxtoem" onClick={toggleNav}>PX ↔ EM</Link></li>
                      <li><Link to="/color-convert" onClick={toggleNav}>RGB ↔ HEX</Link></li>
                    </ul>
                  </div>

                  <div className="tool-category">
                    <h3 className="category-name">IMAGE</h3>
                    <ul className="tool-list">
                      <li>Resize<span className="wait">(오픈예정)</span></li>
                      <li>Crop<span className="wait">(오픈예정)</span></li>
                    </ul>
                  </div>
                  
                  <div className="tool-category">
                    <h3 className="category-name">FONT</h3>
                    <ul className="tool-list">
                      <li><Link to="/webfont" onClick={toggleNav}>WEB FONT</Link></li>
                    </ul>
                  </div>
                  
                  <div className="tool-category">
                    <h3 className="category-name">HTML / CSS</h3>
                    <ul className="tool-list">
                      <li>오픈예정<span className="wait">(오픈예정)</span></li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>
        );
    }
}

export default Navigation;