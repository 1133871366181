import React, { Component } from 'react';

class Licence extends Component {
  render() {
    const {link} = this.props;

    return (
      <>
        <div className="dashed-wrap left">
          <h5 className="info-title deco-text">LICENCE</h5>

          <ul className="info-list">
            <li>
              모든 폰트의 저작권은 각 제작사 및 제작자에 있으며, 툴로에서는 폰트에 대한 안내와 제작자 소개를 드리고 있습니다.
              <br />
              라이선스 관련 자세한 사항은 제작사 / 제작자에게 문의 부탁드리며,
              <br />
              폰트에 따라 활용 범위와 상업적 사용에 제한이 있으므로 사용하시기 전에 라이선스를 꼭 확인 부탁드립니다.
              <br />
              툴로는 폰트 사용으로 발생하는 저작권 및 지적재산권에 대한 모든 일에 책임을 지지 않습니다.
              <br />
              <a href={link? link : ''} target="_blank" class="licence_link">
                라이센스 전문 보기
              </a>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

export default Licence;