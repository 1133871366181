import React, { Component } from 'react';
import './Common.scss';

class Footer extends Component {
    render() {
        return (
            <div id="footer">
              <p>contact : toolo@syneez.com</p>
              <p>Copyright 2019. SYNEEZ. All rights reserved.</p>
            </div>
        );
    }
}

export default Footer;