import React, { Component } from 'react';
import {Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import './Main.scss';

const TITLE = 'Toolo';

class Main extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>{TITLE}</title>
          <meta name="description" content="툴로는 디자인과 개발에 필요한 툴과 도구를 쉽고 간편하게 제공하는 서비스입니다." />
        </Helmet>
      
        <div className="top-title">
          <h2>사용이 쉽고 간편한 Toolo</h2>
          <p>간편하게 수치를 변화시켜드려요!</p>
        </div>
        <div className="content-wrap">
          <div className="sz-wrap">
            {/* ad 영역 */}
            <div className="ad-wrap" />
            <ul className="service-list">
              <li className="design">
                <Link to="/pxtoem">
                  <div className="service-box">
                    <div className="service-title">
                      <span className="service-category">DESIGN</span>
                      <h3>PX↔EM</h3>
                    </div>
                  </div>
                </Link>
              </li>
              <li className="design">
                <Link to="/color-convert">
                  <div className="service-box">
                    <div className="service-title">
                      <span className="service-category">DESIGN</span>
                      <h3>RGB↔HEX</h3>
                    </div>
                  </div>
                </Link>
              </li>
              <li className="image">
                <div className="service-box">
                  <div className="service-title">
                    <span className="service-category">IMAGE</span>
                    <h3>RESIZE</h3>
                    <span className="service-wait">오픈 예정</span>
                  </div>
                </div>
              </li>
              <li className="image">
                <div className="service-box">
                  <div className="service-title">
                    <span className="service-category">IMAGE</span>
                    <h3>CROP</h3>
                    <span className="service-wait">오픈 예정</span>
                  </div>
                </div>
              </li>
              <li className="html">
                <div className="service-box">
                  <div className="service-title">
                    <span className="service-category">HTML/CSS</span>
                    <h3>오픈 예정</h3>
                    <span className="service-wait">오픈 예정</span>
                  </div>
                </div>
              </li>
              <li className="font">
                <Link to="/webfont">
                  <div className="service-box">
                    <div className="service-title">
                      <span className="service-category">FONT</span>
                      <h3>WEB FONT</h3>
                    </div>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}

export default Main;