import React, { Component } from 'react';
import {Route} from 'react-router-dom';

// components
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Main from './components/main';
import Pxtoem from './containers/PxtoemContainer';
import ColorConvert from './containers/ColorConvertContainer';
import Webfont from './containers/WebfontContainer';

// web font components
import NotoSansKRDetail from './containers/font/NotoSansKRContainer';
import GothicA1Detail from './containers/font/GothicA1Container';
import NanumGothicDetail from './containers/font/NanumGothicContainer';
import NanumMyeongjoDetail from './containers/font/NanumMyeongjoContainer';
import NanumBarunGothicDetail from './containers/font/NanumBarunGothicContainer';
import NanumSquareDetail from './containers/font/NanumSquareContainer';
import NanumSquareRoundDetail from './containers/font/NanumSquareRoundContainer';
import NanumBarunPenDetail from './containers/font/NanumBarunPenContainer';
import NanumBrushDetail from './containers/font/NanumBrushContainer';
import GugiDetail from './containers/font/GugiContainer';
import JuaDetail from './containers/font/JuaContainer';
import BlackHanSansDetail from './containers/font/BlackHanSansContainer';
import NixgonDetail from './containers/font/NixgonContainer';

// css
import './assets/css/style.scss';

class App extends Component {
  render() {
    return (
        <>
          <Header />
          
          <div id="container">
            <Route path="/" exact component={Main} />
            <Route path="/pxtoem" component={Pxtoem} />
            <Route path="/color-convert" component={ColorConvert} />
            <Route path="/webfont" component={Webfont} />

            {/* web font */}
            <Route path="/notosanskr/detail" component={NotoSansKRDetail} />
            <Route path="/gothic_a1/detail" component={GothicA1Detail} />
            <Route path="/nanum_gothic/detail" component={NanumGothicDetail} />
            <Route path="/nanum_myeongjo/detail" component={NanumMyeongjoDetail} />
            <Route path="/nanum_barungothic/detail" component={NanumBarunGothicDetail} />
            <Route path="/nanum_square/detail" component={NanumSquareDetail} />
            <Route path="/nanum_squareround/detail" component={NanumSquareRoundDetail} />
            <Route path="/nanum_barunpen/detail" component={NanumBarunPenDetail} />
            <Route path="/nanum_brush/detail" component={NanumBrushDetail} />
            <Route path="/gugi/detail" component={GugiDetail} />
            <Route path="/jua/detail" component={JuaDetail} />
            <Route path="/black_han_sans/detail" component={BlackHanSansDetail} />
            <Route path="/nixgon/detail" component={NixgonDetail} />
          </div>

          <Footer />
        </>
    );
  }
}

export default App;