import React, { Component } from 'react';

import './BlackHanSans.scss';
import '../Webfont.scss';

class BlackHanSansStyle extends Component {
  render() {
    const {input} = this.props;

    return (
      <ul className="font_style-list black_han_sans">
        <li className="weight_400">
          <span className="font_style-category">Regular 400</span>
          <p className="font_style-preview">{input}</p>
          {/* <button type="button" className="font_style_select-btn">폰트 선택</button> */}
        </li>
        <li className="weight_700">
          <span className="font_style-category">Bold 700</span>
          <p className="font_style-preview">{input}</p>
          {/* <button type="button" className="font_style_select-btn">폰트 선택</button> */}
        </li>
      </ul>
    );
  }
}

export default BlackHanSansStyle;