import React, { Component } from 'react';

class pxtoemForm extends Component {
  render() {
    const {input, value, onChange, onSubmit} = this.props;

    return (
      <>
        <form action onSubmit={onSubmit} action="">
          {/* PX => EM */}
          <div className="flex-box">
            <div className="flex-item">
              <div className="dashed-wrap">
                <h4 className="content-title">PX↔EM</h4>
                <div className="input-wrap">
                  <label>현재 Pixel값 입력</label>
                  <p className="input-box">
                    <input type="text" id="pxToEm" value={input.key == 'pxToEm' ? input.value : ''} onChange={onChange} />
                    <span className="input-tail">px</span>
                  </p>
                </div>
                <div className="info-wrap">
                  <h5 className="info-title deco-text">Pixel을 em 사이즈로 변환하는 경우</h5>
                  <ul className="info-list">
                    <li>1. 현재 Pixel값 입력 부분에 현재 pixel 값을 입력</li>
                    <li>2. ‘변환‘ 버튼 클릭</li>
                    <li>3. 변환된 값 부분에 표시되는 em 사이즈를 확인</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* EM => PX */ }
            <div className="flex-item">
              <div className="dashed-wrap">
                <h4 className="content-title">EM↔PX</h4>
                <div className="input-wrap">
                  <label>현재 em값 입력</label>
                  <p className="input-box">
                    <input type="text" id="emToPx" value={input.key == 'emToPx' ? input.value : ''} onChange={onChange} />
                    <span className="input-tail">em</span>
                  </p>
                </div>
                <div className="info-wrap">
                  <h5 className="info-title deco-text">em 사이즈를 Pixel로 변환하는 경우</h5>
                  <ul className="info-list">
                    <li>1. 현재 em값 입력 부분에 현재 pixel 값을 입력</li>
                    <li>2. ‘변환‘ 버튼 클릭</li>
                    <li>3. 변환된 값 부분에 표시되는 pixel 사이즈를 확인</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <p>
            <button type="submit" id="customSet" className="default-btn">변환</button>
          </p>

          <div className="result-wrap">
            <p>변환된 값 <span className="result">{value}</span></p>
          </div>
        </form>
      </>
    );
  }
}

export default pxtoemForm;