import React, { Component } from 'react';
import {connect} from 'react-redux';
import {changeInput} from '../../store/modules/webfont';
import Detail from '../../components/webfont/nixgon/Detail';

class WebfontContainer extends Component {
  handleChange = e => {
    const {value} = e.target;

    this.props.changeInput(value);
  }

  render() {
    const {input} = this.props;

    return (
      <Detail input={input} onChange={this.handleChange} />
    );
  }
}

// state
const mapStateToProps = state => ({
  input: state.webfont.input,
});

// dispatch
const mapDispatchToProps = dispatch => ({
  changeInput: (input) => dispatch(changeInput(input)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WebfontContainer);