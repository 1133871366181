import React, { Component } from 'react';
import {connect} from 'react-redux';
import {changeInput, searchInput, changeFont} from '../store/modules/webfont';
import Webfont from '../components/webfont';

class WebfontContainer extends Component {
  handleChange = e => {
    const {value} = e.target;

    this.props.changeInput(value);
  }

  handleSearch = e => {
    // 검색값 입력
    const {value} = e.target;

    this.props.searchInput(value);

    // 검색값 sort
    const dataSort = () => {
      const {fontArray} = this.props;

      fontArray.sort();
      
      let data = fontArray.filter(d => {
        const checkKor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;

        if (checkKor.test(value)) {
          return d.kor.indexOf(value) > -1;
        } else {
          return d.eng.toLowerCase().indexOf(value.toLowerCase()) > -1;
        }
      });
      
      this.props.changeFont(data);
    }

    dataSort();
  }

  render() {
    const {input, fontArray, searchFont} = this.props;

    return (
      <Webfont input={input} fontArray={fontArray} searchFont={searchFont} onChange={this.handleChange} onSearch={this.handleSearch} />
    );
  }
}

// state
const mapStateToProps = state => ({
  input: state.webfont.input,
  search: state.webfont.search,
  fontArray: state.webfont.fontArray,
  searchFont: state.webfont.searchFont,
});

// dispatch
const mapDispatchToProps = dispatch => ({
  changeInput: (input) => dispatch(changeInput(input)),
  searchInput: (input) => dispatch(searchInput(input)),
  changeFont: (array) => dispatch(changeFont(array)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WebfontContainer);