import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import Form from './Form';
import DefaultSet from './DefaultSet';

import './Pxtoem.scss';

const TITLE = 'PX / EM 변환하기';

class Pxtoem extends Component {
  render() {
    const {input, value, onDefaultSet, onChange, onSubmit} = this.props;

    return (
      <>
        <Helmet>
          <title>{TITLE}</title>
          <meta name="description" content="px을 em으로, em을 px로 변환하기. 반응형 웹 사이즈 계산. px,em convert calculate, generate" />
        </Helmet>

        <div className="top-title design">
          <h2>PX↔EM 변환하기</h2>
          <p>PX to EM Tool은 pixel을 em 사이즈로 변환 (또는 em 사이즈를 pixel로 변환)해주는 도구입니다.</p>
        </div>
        
        <div className="content-wrap">
          <div className="sz-wrap">
            {/* ad 영역 */}
            <div className="ad-wrap" />
            <div className="gray-wrap top">
              <h3 className="sub-title">기준값(상위 요소 값) 변경</h3>
              
              <DefaultSet onDefaultSet={onDefaultSet}></DefaultSet>

              <p className="sub-text">
                기준값은 16px이 기본으로 지정되어 있습니다.
                <br />
                기준값 변경이 필요하신 경우 아래 부분에 기준 Pixel 사이즈를 입력하고, ‘저장’ 버튼을 클릭해주세요.
              </p>
            </div>

            <Form input={input} value={value} onChange={onChange} onSubmit={onSubmit} />
            
            <div className="gray-wrap">
              <div className="em-table">
                <table>
                  <caption className="project-title-sub">기준 pixel에 대한 em, pt</caption>
                  <thead>
                    <tr>
                      <th>PX</th>
                      <th>EM</th>
                      <th>PT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>10px</td>
                      <td>0.63em</td>
                      <td>8pt</td>
                    </tr>
                    <tr>
                      <td>11px</td>
                      <td>0.69em</td>
                      <td>8pt</td>
                    </tr>
                    <tr>
                      <td>12px</td>
                      <td>0.75em</td>
                      <td>9pt</td>
                    </tr>
                    <tr>
                      <td>13px</td>
                      <td>0.81em</td>
                      <td>10pt</td>
                    </tr>
                    <tr>
                      <td>14px</td>
                      <td>0.88em</td>
                      <td>11pt</td>
                    </tr>
                    <tr>
                      <td>15px</td>
                      <td>0.94em</td>
                      <td>11pt</td>
                    </tr>
                    <tr className="default">
                      <td>16px</td>
                      <td>1.00em</td>
                      <td>12pt</td>
                    </tr>
                    <tr>
                      <td>17px</td>
                      <td>1.06em</td>
                      <td>13pt</td>
                    </tr>
                    <tr>
                      <td>18px</td>
                      <td>1.13em</td>
                      <td>14pt</td>
                    </tr>
                    <tr>
                      <td>19px</td>
                      <td>1.19em</td>
                      <td>14pt</td>
                    </tr>
                    <tr>
                      <td>20px</td>
                      <td>1.25em</td>
                      <td>15pt</td>
                    </tr>
                    <tr>
                      <td>21px</td>
                      <td>1.31em</td>
                      <td>16pt</td>
                    </tr>
                    <tr>
                      <td>22px</td>
                      <td>1.38em</td>
                      <td>17pt</td>
                    </tr>
                    <tr>
                      <td>23px</td>
                      <td>1.44em</td>
                      <td>17pt</td>
                    </tr>
                    <tr>
                      <td>24px</td>
                      <td>1.50em</td>
                      <td>18pt</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* END .em-table */}
            </div>
            {/* ad 영역 */}
            <div className="ad-wrap" />
          </div>
        </div>
      </>
    );
  }
}

export default Pxtoem;