import React, { Component, Fragment } from 'react';

class List extends Component {
  render() {
    const {fontArray, searchFont, input, onChange} = this.props;
    let fc = []
    fc = searchFont.length < 1 ? fontArray : searchFont;

    return (
      <>
        <ul className="link-list">
          {fc.map(
              (d, i) => {
                const FontComponent = d.fontClass;

                return <FontComponent key={i} input={input} onChange={onChange} />;
              }
          )}
        </ul>
      </>
    );
  }
}

export default List;