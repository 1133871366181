import React, { Component } from 'react';
import {Helmet} from 'react-helmet';

import List from './List';

const TITLE = 'Web Font';

class Webfont extends Component {
  render() {
    const {input, fontArray, searchFont, onChange, onSearch} = this.props;

    return (
      <>
        <Helmet>
          <title>{TITLE}</title>
          <meta name="description" content="무료 한글 웹폰트를 사용해보세요. 링크로 바로 활용이 가능합니다" />
        </Helmet>

        <div className="top-title font">
          <h2>WEB FONT</h2>
          <p>웹폰트로 이용가능한 무료 한글 폰트 입니다.</p>
        </div>
        <div className="content-wrap">
          <div className="sz-wrap">
            {/* ad 영역 */}
            <div className="ad-wrap" />

            <div className="gray-wrap top">
              
              <div className="flex-box">
                <div className="font-search flex-item">
                  <h3 className="sub-title">폰트 검색</h3>
                  <div className="input-wrap white full">
                    <p className="input-box">
                      <span>폰트명 입력</span>
                      <input className="input-text" onChange={onSearch} placeholder="검색할 폰트 이름을 입력해주세요." />
                    </p>
                  </div>
                </div>
                <div className="font-input flex-item">
                  <h3 className="sub-title">문장 입력</h3>
                  <div className="input-wrap white full">
                    <p className="input-box">
                      <input type="text" id="textInput" className="input-text" onChange={onChange} placeholder="텍스트 미리보기" />
                    </p>
                  </div>
                </div>
              </div>

              <p className="sub-text">
                * 폰트의 지적재산권은 각 제작자 및 제작사에 있으며, 툴로는 폰트의 사용으로 발생하는 모든 일에 대한 책임을 지지 않습니다.
                <br />
                폰트 사용 가능 범위 및 라이센스는 관련 정확한 사항은 서체제작자의 규정을 확인해 주세요.
              </p>
            </div>

            <List fontArray={fontArray} searchFont={searchFont} input={input} onChange={onChange} />
            
            {/* ad 영역 */}
            <div className="ad-wrap" />
          </div>
        </div>
      </>
    );
  }
}

export default Webfont;