export function fadeIn(el, delay, smooth = true, displayStyle = 'block') {
    el.style.opacity = 0;
    el.style.display = displayStyle;
    if (smooth) {
        let opacity = 0;
        let request;

        const animation = () => {
            el.style.opacity = opacity += 0.04;
            if (opacity >= 1) {
                opacity = 1;
                cancelAnimationFrame(request);
            }
        };

        const rAf = () => {
            request = requestAnimationFrame(rAf);
            animation();
        };

        setTimeout(function(){
            rAf();
        }, delay)

    } else {
        el.style.opacity = 1;
    }
};