import {createAction, handleActions} from 'redux-actions';

import NotoSansKR from '../../components/webfont/notosans_kr';
import GothicA1 from '../../components/webfont/gothic_a1';
import NanumGothic from '../../components/webfont/nanum_gothic';
import NanumMyeongjo from '../../components/webfont/nanum_myeongjo';
import NanumBarunGothic from '../../components/webfont/nanum_barungothic';
import NanumSquare from '../../components/webfont/nanum_square';
import NanumSquareRound from '../../components/webfont/nanum_squareround';
import NanumBarunPen from '../../components/webfont/nanum_barunpen';
import NanumBrush from '../../components/webfont/nanum_brush';
import Gugi from '../../components/webfont/gugi';
import Jua from '../../components/webfont/jua';
import BlackHanSans from '../../components/webfont/black_han_sans';
import Nixgon from '../../components/webfont/nixgon';

const CHANGE_INPUT = 'webfont/CHANGE_INPUT';
const SEARCH_INPUT = 'webfont/SEARCH_INPUT';
const CHANGE_FONT = 'webfont/CHANGE_FONT';

export const changeInput = createAction(CHANGE_INPUT, input => input);
export const searchInput = createAction(SEARCH_INPUT, input => input);
export const changeFont = createAction(CHANGE_FONT, array => array);

// state
const initailState = {
  input: '마법에 걸릴 거에요',
  search: '',
  fontArray: [
    {fontClass: NotoSansKR, eng: 'NotoSansKR', kor: '본고딕'}, {fontClass: GothicA1, eng: 'GothicA1', kor: 'GothicA1'}, 
    {fontClass: NanumGothic, eng: 'NanumGothic', kor: '나눔고딕, 나눔 고딕'}, {fontClass: NanumMyeongjo, eng: 'NanumMyeongjo', kor: '나눔명조, 나눔 명조'}, 
    {fontClass: NanumBarunGothic, eng: 'NanumBarunGothic', kor: '나눔바른고딕, 나눔 바른 고딕'}, 
    {fontClass: NanumSquare, eng: 'NanumSquare', kor: '나눔스퀘어, 나눔 스퀘어'},
    {fontClass: NanumSquareRound, eng: 'NanumSquareRound', kor: '나눔스퀘어라운드, 나눔 스퀘어 라운드'},
    {fontClass: NanumBarunPen, eng: 'NanumBarunPen', kor: '나눔바른펜, 나눔 바른 펜'}, {fontClass: NanumBrush, eng: 'NanumBrush', kor: '나눔손글씨, 나눔 손글씨'},    
    {fontClass: Gugi, eng: 'Gugi', kor: '구기체'}, {fontClass: Jua, eng: 'Jua', kor: '배민주아체'}, 
    {fontClass: BlackHanSans, eng: 'BlackHanSans', kor: '검은고딕체'}, {fontClass: Nixgon, eng: 'Nixgon', kor: '닉스곤체'},
  ],
  searchFont: [],
}

// reducer
export default handleActions({
    [CHANGE_INPUT]: (state, action) => ({
      ...state,
      input: action.payload,
    }),
    [SEARCH_INPUT]: (state, action) => ({
      ...state,
      search: action.payload,
    }),
    [CHANGE_FONT]: (state, action) => ({
      ...state,
      searchFont: action.payload,
    }),
  },
  initailState,
);