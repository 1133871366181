import React, { Component } from 'react';
import {Route, Link} from 'react-router-dom';

import './BlackHanSans.scss';

class BlackHanSans extends Component {
  render() {
    const {input, onChange} = this.props;

    return (
      <li>
        <Link to="/black_han_sans/detail">
          <div className="dashed-wrap black_han_sans">
            <h4 className="content-title">
              <span className="font-company">Google, Zess Type</span>
              <span className="font-title">검은고딕체 / Black Han Sans</span>
            </h4>
            <p className="font-text">{input}</p>
          </div>
        </Link>
      </li>
    );
  }
}

export default BlackHanSans;