import React, { Component } from 'react';
import {Route, Link} from 'react-router-dom';

import './NanumBrush.scss';

class NanumBrush extends Component {
  render() {
    const {input, onChange} = this.props;

    return (
      <li>
        <Link to="/nanum_brush/detail">
          <div className="dashed-wrap nanum_brush">
            <h4 className="content-title">
              <span className="font-company">Naver, Sandoll</span>
              <span className="font-title">나눔손글씨 붓 / Nanum Brush</span>
            </h4>
            <p className="font-text">{input}</p>
          </div>
        </Link>
      </li>
    );
  }
}

export default NanumBrush;