import React, { Component } from 'react';
import {connect} from 'react-redux';
import {changeInput, defaultSet, convert} from '../store/modules/pxtoem';
import Pxtoem from '../components/pxtoem';

import {isNumeric} from '../components/utils/Regix';
import {btnFocus} from '../components/animation/BtnFocus';

class PxtoemContainer extends Component {
  handleChange = e => {
    const {value} = e.target;
    const key = e.target.getAttribute('id');

		this.props.changeInput({key: key, value: value});
  };

  handleSubmit = e => {
    e.preventDefault();

    const {input, defaultValue} = this.props;

    // 숫자인지 테스트
    let numCheck = isNumeric(input.value);
    if (numCheck === false) {
      alert('숫자만 입력해주세요!');
      return false;
    }
    
    const pxToEmSet = function (val) {
      let result = val / defaultValue;
      result = result.toFixed(2);

      return result;
    }

    const emToPxSet = function (val) {
      let result = val * defaultValue;

      return result;
    }

    let result = '';
    if ( input.key == 'pxToEm' ) {
      result = pxToEmSet(input.value);
      result += ' em';
    } else if ( input.key == 'emToPx' ) {
      result = emToPxSet(input.value);
      result += ' px';
    }

    // btn focus
    btnFocus(e.target);

    this.props.convert(result);
  };
  
  handleDefaultChange = e => {
    const {value} = e.target;

    this.props.defaultSet(value);
  }

  render() {
    const {input, value} = this.props;

    return (
      <Pxtoem input={input} value={value} onDefaultSet={this.handleDefaultChange} onChange={this.handleChange} onSubmit={this.handleSubmit} />
    );
  }
}

// state
const mapStateToProps = state => ({
  defaultValue: state.pxtoem.defaultValue,
  input: state.pxtoem.input,
  value: state.pxtoem.value,
});

// dispatch
const mapDispatchToProps = dispatch => ({
  defaultSet: (value) => dispatch(defaultSet(value)),
  changeInput: (input) => dispatch(changeInput(input)),
  convert: (value) => dispatch(convert(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PxtoemContainer);