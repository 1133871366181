import React, { Component } from 'react';
import {Route, Link} from 'react-router-dom';

import './NanumSquareRound.scss';

class NanumSquareRound extends Component {
  render() {
    const {input, onChange} = this.props;

    return (
      <li>
        <Link to="/nanum_squareround/detail">
          <div className="dashed-wrap nanum_squareround">
            <h4 className="content-title">
              <span className="font-company">Naver, Sandoll</span>
              <span className="font-title">나눔스퀘어라운드 / Nanum Square Round</span>
            </h4>
            <p className="font-text">{input}</p>
          </div>
        </Link>
      </li>
    );
  }
}

export default NanumSquareRound;