import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import Style from './Style';
import Licence from '../Licence';

import './Jua.scss';
import '../Webfont.scss';

const TITLE = '배민주아체 / Jua';

class JuaDetail extends Component {
  state = {
    url: '',
    name: '',
    link: 'https://www.woowahan.com/#/fonts'
  }

  render() {
    const {link} = this.state;
    const {input, onChange} = this.props;
    const defaultInput = '마법에 걸릴 거에요';

    return (
      <>
        <Helmet>
          <title>{TITLE}</title>
          <meta name="description" content="Jua. 배민주아체. 배달의민족 웹폰트를 쉽고 간편하게 사용해보세요" />
        </Helmet>

        <div className="top-title font">
          <h2>배민주아체 / Jua</h2>
          <p>TAE System & Typefaces Co.</p>
        </div>
        
        <div className="content-wrap">
          <div className="sz-wrap">
            {/* ad 영역 */}
            <div className="ad-wrap" />

            <div className="gray-wrap top">
              <h3 className="sub-title">문장 입력</h3>
              <div className="input-wrap white full">
                <p className="input-box">
                  <input type="text" id="textInput" className="input-text" value={input == defaultInput ? '' : input} onChange={onChange} />
                </p>
              </div>
              <p className="sub-text">
                * 폰트의 지적재산권은 각 제작자 및 제작사에 있으며, 툴로는 폰트의 사용으로 발생하는 모든 일에 대한 책임을 지지 않습니다.
                <br />
                폰트 사용 가능 범위 및 라이센스는 관련 정확한 사항은 서체제작자의 규정을 확인해 주세요.
              </p>
            </div>
            
            <div className="flex-box right_sidebar">
              <div className="flex-item">
                <div className="dashed-wrap">
                  <h4 className="content-title">STYLE</h4>
                  <Style input={input} />
                </div>
              </div>
              <div className="flex-item">
                <div className="dashed-wrap dashed-wrap--embed">
                  <h4 className="content-title">EMBED</h4> 
                  
                  <ul className="font_embed-list">
                      <li>
                        <h5 className="font_embed-title">&lt;LINK&gt;</h5>
                        <p className="font_embed-link">
                            &lt;link href="//cdn.jsdelivr.net/gh/snz-hayden/toolo/fonts/jua/jua.css" rel="stylesheet"&gt;
                        </p>
                      </li>

                      <li>
                        <h5 className="font_embed-title">@import</h5>
                        <p className="font_embed-link">
                            @import url('//cdn.jsdelivr.net/gh/snz-hayden/toolo/fonts/jua/jua.css');
                        </p>
                      </li>

                      <li>
                        <h5 className="font_embed-title">Family name</h5>
                        <p className="font_embed-link">
                            font-family: 'Jua', sans-serif;
                        </p>
                      </li>
                  </ul>
                </div>
              </div>
            </div>

            <Licence link={link} />
            
            {/* ad 영역 */}
            <div className="ad-wrap" />
          </div>
        </div>
      </>
    );
  }
}

export default JuaDetail;