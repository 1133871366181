import React, { Component } from 'react';
import {Route, Link} from 'react-router-dom';

import './Jua.scss';

class Jua extends Component {
  render() {
    const {input, onChange} = this.props;

    return (
      <li>
        <Link to="/jua/detail">
          <div className="dashed-wrap jua">
            <h4 className="content-title">
              <span className="font-company">Woowahan Brothers</span>
              <span className="font-title">배민주아체 / Jua</span>
            </h4>
            <p className="font-text">{input}</p>
          </div>
        </Link>
      </li>
    );
  }
}

export default Jua;