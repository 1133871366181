import React, { Component } from 'react';
import {connect} from 'react-redux';
import {changeInput, convert, pickerConvert} from '../store/modules/colorConvert';
import ColorConvert from '../components/color-convert';

import {btnFocus} from '../components/animation/BtnFocus';

class ColorConvertContainer extends Component {
  handleChange = e => {
    const {value} = e.target;
    const key = e.target.getAttribute('id');

		this.props.changeInput({key: key, value: value});
  };

  handlePickerChange = color => {
    this.props.pickerConvert(color);
  }

  handleSubmit = e => {
    e.preventDefault();

    const {input} = this.props;

    // rgb값 체크
    const rgbCheckFn = function(v) {
      let vS = v.split(',');
      let vCheck = true;

      for (var i = 0; i < vS.length; i++) {
        if (vS[i] < 0 || vS[i] > 255) {
          vCheck = false;
        }
      }

      if (vS.length != 3 || vCheck == false) {
        return false;
      }

      return true;
    }

    // rgb => hex
    const rgbToHex = function (v) {
      if (rgbCheckFn(v) == false) {
        alert('입력값을 확인해주세요');

        return false;
      }

      let hex;
      let rgb = v.split(',');

      const rgbOb = {
        red: rgb[0],
        green: rgb[1],
        blue: rgb[2],
      }

      const to16 = function (num) {
        num = Math.floor(num);

        if (num > -1 && num < 10) {
          num = String(num);

          return num;
        }

        num = num.toString(16);

        return num;
      }

      const toHex = function (num) {
        let result = num / 16;
        let resultAnd = num % 16;

        result = to16(result);
        resultAnd = to16(resultAnd);

        return result + resultAnd;
      }

      rgbOb.red = toHex(rgbOb.red);
      rgbOb.green = toHex(rgbOb.green);
      rgbOb.blue = toHex(rgbOb.blue);

      hex = '#' + rgbOb.red + rgbOb.green + rgbOb.blue;

      return hex;
    }

    // hex값 체크
    const hexCheckFn = function(v) {
      if (v.length < 6 || v.length > 7) {
        return false;
      }

      return;
    }

    // hex => rgb
    const hexToRgb = function (v) {
      if (hexCheckFn(v) == false) {
        alert('입력값을 확인해주세요');

        return false;
      }

      let rgbTotal;

      // 입력값 # 검사
      if (v.search('#') > -1) {
        var hex = v.split('#');
        hex = hex[1];
      } else {
        var hex = v;
      }

      var rgb = {
        red: hex.substr(0, 2),
        green: hex.substr(2, 2),
        blue: hex.substr(4, 2),
      }

      var toNum = function (num) {
        if (num < 10) {
          return num;
        }

        var numC;

        numC = parseInt(num, 16);

        return numC;
      }


      var to10 = function (num) {
        var total;
        var num1 = num.substr(0, 1);
        var num2 = num.substr(1, 1);

        num1 = toNum(num1);
        num2 = toNum(num2);

        num1 = num1 * 16;
        total = num1 + num2 * 1;

        return total;
      }

      rgb.red = to10(rgb.red);
      rgb.green = to10(rgb.green);
      rgb.blue = to10(rgb.blue);

      rgbTotal = rgb.red + ',' + rgb.green + ',' + rgb.blue;

      return rgbTotal;
    }

    let result = '';
    if ( input.key =='rgbConvert' ) {
      result = rgbToHex(input.value);
    } else if ( input.key =='hexConvert' ) {
      result = hexToRgb(input.value);
    }

    // btn focus
    btnFocus(e.target);

    this.props.convert(result);
  };

  render() {
    const {input, value} = this.props;

    return (
      <ColorConvert input={input} value={value} onChange={this.handleChange} onPickerChange={this.handlePickerChange} onSubmit={this.handleSubmit} />
    );
  }
}

// state
const mapStateToProps = state => ({
  input: state.colorConvert.input,
  value: state.colorConvert.value,
  pickerValue: state.colorConvert.pickerValue,
});

// dispatch
const mapDispatchToProps = dispatch => ({
  changeInput: (input) => dispatch(changeInput(input)),
  convert: (value) => dispatch(convert(value)),
  pickerConvert: (value) => dispatch(pickerConvert(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ColorConvertContainer);