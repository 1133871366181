import React, { Component } from 'react';
import {ChromePicker} from 'react-color';

class ColorConvert extends Component {
  state = {
    Background: '#fff',
  }

  handleChangeComplete = (color) => {
    this.setState({
      background: color.hex
    });

    this.props.onPickerChange(this.state.background);
  };

  render() {
    const {input, value} = this.props;
    
    let css = input.key == 'hexConvert' ? `rgb(${value})` : value;

    return (
      <>
        <div className="gray-wrap">
          <h3 className="gray-title">컬러피커로 찾아보기</h3>
          <div className="flex-box">
            <div className="picker-wrap flex-item">
              <div className="picker-box">
                <ChromePicker 
                color = {
                  this.state.background
                }
                onChangeComplete = {
                  this.handleChangeComplete
                }
                disableAlpha = {
                  true
                }
                />
              </div>
            </div>
            <div className="picker-info flex-item">
              <p className="picker-info-text">
                컬러피커 오른쪽 하단의 파란색 박스를 클릭하시면
                <br />
                <b>RGB, HEX, HSL</b>을 확인하실 수 있습니다.
              </p>
              <div className="result-css">
                <span class="result-css-title">CSS 가이드</span>
                <p className="result-box">
                  <label htmlFor="color">Color</label>
                  <input type="text" value={`color: ${css};`} id="color" className="css square-input" />
                </p>
                <p className="result-box">
                  <label htmlFor="borderColor">Border-Color</label>
                  <input type="text" value={`background-color: ${css};`} id="borderColor" className="css square-input" />
                </p>
                <p className="result-box">
                  <label htmlFor="backgroundColor">Border-Color</label>
                  <input type="text" value={`border-color: ${css};`} id="backgroundColor" className="css square-input" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ColorConvert;