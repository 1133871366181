import React from 'react';
import {render, hydrate} from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// add redux store
import rootReducer from './store/modules';
import {createStore} from 'redux';
import {Provider} from 'react-redux';

// redux store
const store = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

// react-snap
const rootElement = document.getElementById('container');

if (rootElement.hasChildNodes()) {
    hydrate(
        <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>, 
        rootElement
    );
} else {
    render(
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>, 
        rootElement
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
