import React, { Component } from 'react';
import {Route, Link} from 'react-router-dom';

import './NotoSansKR.scss';

class NotoSansKR extends Component {
  render() {
    const {input, onChange} = this.props;

    return (
      <li>
        <Link to="/notosanskr/detail">
          <div className="dashed-wrap noto_sans_kr">
            <h4 className="content-title">
              <span className="font-company">Google</span>
              <span className="font-title">Noto Sans KR</span>
            </h4>
            <p className="font-text">{input}</p>
          </div>
        </Link>
      </li>
    );
  }
}

export default NotoSansKR;