import {createAction, handleActions} from 'redux-actions';

const DEFAULT_SET = 'pxtoem/DEFAULT_SET';
const CHANGE_INPUT = 'pxtoem/CHANGE_INPUT';
const CONVERT = 'pxtoem/CONVERT';

export const defaultSet = createAction(DEFAULT_SET, value => value);
export const changeInput = createAction(CHANGE_INPUT, input => input);
export const convert = createAction(CONVERT, value => value);

// state
const initailState = {
  defaultValue: 16,
  input: {
    key: '',
    value: 0,
  },
  value: '',
}

// reducer
export default handleActions({
    [DEFAULT_SET]: (state, action) => ({
      ...state,
      defaultValue: action.payload,
    }),
    [CHANGE_INPUT]: (state, action) => ({
      ...state,
      input: action.payload,
    }),
    [CONVERT]: (state, action) => ({
      ...state,
      value: action.payload,
    }),
  },
  initailState,
);